@use '../node_modules/sanitize.css/evergreen.css';

*,
::before,
::after {
    background-repeat: no-repeat;
    mask-repeat: no-repeat;
}

/**
 * 1. Don't behave as inline element by default which creates weird situations with margins
 * 2. Never stretch images or let them go out of the viewport
 * 3. Keep original image ratio
 * 4. https://www.zachleat.com/web/fluid-images/
 */
img {
    display: inline-block; /* 1 */
    height: auto; /* 3 */
    max-width: 100%; /* 2 */
    vertical-align: top;

    /*&[width] {
        width: auto; /* 4 Defer to max-width
    }

    &[width][height] {
        height: auto; /* 4 Preserve aspect ratio
    }

    /* 4 Let SVG scale without boundaries
    &[src$='.svg'] {
        width: 100%;
        height: auto;
        max-width: none;
    }*/
}

/**
 * Inherit parent color, more convenient than default browser blue
 */
a {
    color: inherit;
}

figure {
    margin-left: 0;
    margin-right: 0;
}

/**
 * HARD reset to remove shadow in input for IOS
 */
input:not([type='checkbox'], [type='radio']) {
    -webkit-appearance: none !important;
}

/**
 * --------------------
 * START a11y-css-reset
 * https://github.com/mike-engel/a11y-css-reset
 */
*:focus:not(:focus-visible),
*::before:focus:not(:focus-visible),
*::after:focus:not(:focus-visible) {
    outline: none;
}

/* https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html */
ul, ol {
    list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
    padding-left: 0;
    margin: 0;
}

/* prettier-ignore */
@media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
        transition: none !important;
        animation: none !important;
        scroll-behavior: auto !important;
    }
}

/**
 * END a11y-css-reset
 * ------------------
 */
